<script>
// 1. Import Chart.js so you can use the global Chart object
import Chart from 'chart.js'
// 2. Import the `generateChart()` method to create the vue component.
import { generateChart } from 'vue-chartjs'

// 3. Extend on of the default charts
// http://www.chartjs.org/docs/latest/developers/charts.html
Chart.defaults.LineWithBar = Chart.defaults.bar
Chart.controllers.LineWithBar = Chart.controllers.line.extend({
/* custom magic here */
})

// 4. Generate the vue-chartjs component
// First argument is the chart-id, second the chart type.
const CustomLine = generateChart('custom-line')

// 5. Extend the CustomLine Component just like you do with the default vue-chartjs charts.
export default {
  extends: CustomLine,
  props: {
    updateStatus: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
  },
  watch: {
    updateStatus () {
      this.setMixChart()
    }
  },
  data () {
    return {
      // updateStatus: props.updateStatus
    }
  },
  methods: {
    getChartlabels () {
      const labels = []
      this.updateStatus.forEach((row, index) => {
        // if (index === 0)labels.push(row.updateDate)
        // else if (index === this.updateStatus.length - 1)labels.push(row.updateDate)
        // else labels.push('')
        labels.push(row.updateDate)
      })
      return labels
    },
    getChartData () {
      const data = []
      this.updateStatus.forEach(row => {
        data.push(row.updateCount)
      })
      return data
    },
    setMixChart () {
      const getLabels = this.getChartlabels()
      const getDatas = this.getChartData()

      this.renderChart(
        {
          labels: getLabels,
          datasets: [
            {
              type: 'line',
              data: getDatas,
              borderColor: '#9AC7DB',
              borderWidth: 1,
              backgroundColor: this.gradient,
              pointBorderWidth: 1,
              pointRadius: 3,
              pointBackgroundColor: '#FFFFFF',
              // backgroundColor: 'rgba(154,199,219,0.5)',
              lineTension: 0
            }
          ]
        },
        {
          maintainAspectRatio: false, // option 추가
          legend: false,
          tooltips: {
            enabled: true
          },
          scales: {
            xAxes: [{
              offset: true,
              gridLines: {
                display: false
              },
              ticks: {
                maxTicksLimit: 13,
                maxRotation: 0,
                minRotation: 0,
                beginAtZero: true,
                fontColor: '#959595', // 폰트컬러
                fontSize: 11 // 폰트사이즈,
              }
            }],
            yAxes: [{
              display: true,
              position: 'right',
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: '#959595',
                fontSize: 11,
                suggestedMin: 0,
                suggestedMax: 80
                // stepSize: 0.5
              }
            }]
          }
        }
      )
    }
  },
  mounted () {
    this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 50, 0, 190)

    this.gradient.addColorStop(0, '#9AC7DB')
    this.gradient.addColorStop(1, '#FFFFFF00')

    if (this.updateStatus !== undefined) {
      this.setMixChart()
    } else {
    }
  }
}
</script>
