var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("h2", { staticClass: "page-title-bar" }, [
      _c("i", { staticClass: "ico ico-overview" }),
      _vm._v(_vm._s(_vm.$t("Overview")) + " ")
    ]),
    _c("div", { staticClass: "state-info", staticStyle: { height: "16px" } }, [
      _c("p"),
      _c("ul", [
        _c("li", { staticClass: "state-danger" }, [
          _vm._v(_vm._s(_vm.$t("DANGER")))
        ]),
        _c("li", { staticClass: "state-caution" }, [
          _vm._v(_vm._s(_vm.$t("CAUTION")))
        ]),
        _c("li", { staticClass: "state-stable" }, [
          _vm._v(_vm._s(_vm.$t("STABLE")))
        ])
      ])
    ]),
    _c("div", { staticClass: "main-layout layout01" }, [
      _c("div", { staticClass: "main-section" }, [
        _c("h3", { staticClass: "tit-section" }, [
          _vm._v(_vm._s(_vm.$t("LCD Status")))
        ]),
        _c(
          "div",
          { staticClass: "group" },
          [
            _c(
              "v-btn",
              {
                class: "stateBox lcdStateBox " + _vm.scheduleStatus,
                on: {
                  click: function($event) {
                    return _vm.clickStatus("scheduled")
                  }
                }
              },
              [
                _c("dl", [
                  _c("dt", { staticStyle: { "padding-left": "0px" } }, [
                    _c(
                      "strong",
                      {
                        staticStyle: { width: "100%", "text-align": "center" }
                      },
                      [_vm._v(_vm._s(_vm.$t("Scheduled LCD")))]
                    )
                  ]),
                  _c("dd", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.getPercent(
                              _vm.lcdStatus.scheduled,
                              _vm.lcdCount
                            )
                          ) + "%"
                        )
                      ])
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.lcdStatus.scheduled
                              ? _vm.lcdStatus.scheduled
                              : 0
                          ) +
                          "ea / " +
                          _vm._s(_vm.lcdCount) +
                          "ea "
                      )
                    ])
                  ])
                ])
              ]
            ),
            _c(
              "v-btn",
              {
                class: "stateBox lcdStateBox " + _vm.noScheduleStatus,
                on: {
                  click: function($event) {
                    return _vm.clickStatus("noscheduled")
                  }
                }
              },
              [
                _c("dl", [
                  _c("dt", { staticStyle: { "padding-left": "0px" } }, [
                    _c(
                      "strong",
                      {
                        staticStyle: { width: "100%", "text-align": "center" }
                      },
                      [_vm._v(_vm._s(_vm.$t("No Scheduled LCD")))]
                    )
                  ]),
                  _c("dd", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.getPercent(
                              _vm.lcdStatus.noScheduled,
                              _vm.lcdCount
                            )
                          ) + "%"
                        )
                      ])
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.lcdStatus.noScheduled
                              ? _vm.lcdStatus.noScheduled
                              : 0
                          ) +
                          "ea / " +
                          _vm._s(_vm.lcdCount) +
                          "ea "
                      )
                    ])
                  ])
                ])
              ]
            ),
            _c(
              "v-btn",
              {
                class: "stateBox lcdStateBox " + _vm.offlineStatus,
                on: {
                  click: function($event) {
                    return _vm.clickStatus("offline")
                  }
                }
              },
              [
                _c("dl", [
                  _c("dt", { staticStyle: { "padding-left": "0px" } }, [
                    _c(
                      "strong",
                      {
                        staticStyle: { width: "100%", "text-align": "center" }
                      },
                      [_vm._v(_vm._s(_vm.$t("Offline LCD")))]
                    )
                  ]),
                  _c("dd", [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.getPercent(_vm.lcdStatus.offline, _vm.lcdCount)
                          ) + "%"
                        )
                      ])
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.lcdStatus.offline ? _vm.lcdStatus.offline : 0
                          ) +
                          "ea / " +
                          _vm._s(_vm.lcdCount) +
                          "ea "
                      )
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "stateBox lcdStateBox pa-6" }, [
          _c("h4", { staticClass: "tit-sub" }, [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm.$t("Weekly Consumer Response Rate by Date & Time zone")
                )
              )
            ])
          ]),
          _c("div", { staticClass: "d-flex flex-row flex-title" }, [
            _c(
              "div",
              {
                staticClass: "d-flex",
                staticStyle: { "font-size": "small", color: "#959595" }
              },
              [_vm._v("(" + _vm._s(_vm.$t("times")) + ")")]
            ),
            _c(
              "div",
              {
                staticClass: "d-flex",
                staticStyle: { "font-size": "small", color: "#959595" }
              },
              [_vm._v("(" + _vm._s(_vm.$t("Average times")) + ")")]
            )
          ]),
          _c("div", { staticClass: "graph-layout mt-3" }, [
            _c(
              "div",
              { staticClass: "graph-section" },
              [
                _c("barChart", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.progressBarShow === "block",
                      expression: "progressBarShow === 'block'"
                    }
                  ],
                  attrs: { height: 194, updateStatus: _vm.updateStatusDate }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "graph-section" },
              [
                _c("lineChart", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.progressBarShow === "block",
                      expression: "progressBarShow === 'block'"
                    }
                  ],
                  attrs: { height: 194, updateStatus: _vm.updateStatusTime }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "d-flex flex-row flex-footer" }, [
            _c(
              "div",
              {
                staticClass: "d-flex",
                staticStyle: {
                  "font-size": "small",
                  color: "#959595",
                  "margin-right": "13px"
                }
              },
              [_vm._v(_vm._s(_vm.$t("Date")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex",
                staticStyle: {
                  "font-size": "small",
                  color: "#959595",
                  "margin-left": "13px"
                }
              },
              [_vm._v(_vm._s(_vm.$t("Time")))]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "main-section" },
        [
          _c("h3", { staticClass: "tit-section" }, [
            _vm._v(_vm._s(_vm.$t("Weekly Contents Ranking")))
          ]),
          _c(
            "perfect-scrollbar",
            { style: { height: "475px" } },
            [
              _vm.contentsRank.length !== 0
                ? _c(
                    "v-expansion-panels",
                    {
                      staticClass: "ranking-panel",
                      attrs: { focusable: "" },
                      model: {
                        value: _vm.expanded,
                        callback: function($$v) {
                          _vm.expanded = $$v
                        },
                        expression: "expanded"
                      }
                    },
                    _vm._l(_vm.contentsRank, function(item, i) {
                      return _c(
                        "v-expansion-panel",
                        { key: i },
                        [
                          _c("v-expansion-panel-header", [
                            _c("span", [
                              _c("span", [_vm._v(" " + _vm._s(i + 1) + " ")]),
                              _vm._v(_vm._s(item.media_name))
                            ])
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("div", { staticClass: "lineBar" }, [
                                _c(
                                  "div",
                                  { staticClass: "lineBar-title mr-2" },
                                  [_vm._v(_vm._s(_vm.$t("Play Times")))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "lineBar-slider-group" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "lineBar-slider d-flex" },
                                      [
                                        _c("v-progress-linear", {
                                          staticStyle: { flex: "3" },
                                          attrs: {
                                            value: _vm.getGraph(
                                              item.play_sum,
                                              _vm.rateAverage.playTime
                                            ),
                                            height: "16"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "lineBar-data ml-3",
                                            staticStyle: { flex: "2" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.play_sum) +
                                                " " +
                                                _vm._s(_vm.$t("times"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "lineBar-blank-slider d-flex",
                                        staticStyle: { width: "100%" }
                                      },
                                      [
                                        _c("v-progress-linear", {
                                          staticClass: "progress",
                                          staticStyle: { flex: "3" },
                                          attrs: {
                                            value: _vm.getGraph(
                                              _vm.rateAverage.playTime,
                                              item.play_sum
                                            ),
                                            height: "16"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "lineBar-data ml-3",
                                            staticStyle: { flex: "2" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Average")) +
                                                " " +
                                                _vm._s(
                                                  _vm.rateAverage.playTime
                                                ) +
                                                " " +
                                                _vm._s(_vm.$t("times"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _c("v-divider", { staticClass: "my-8" }),
                              _c("div", { staticClass: "lineBar" }, [
                                _c(
                                  "div",
                                  { staticClass: "lineBar-title mr-2" },
                                  [_vm._v(_vm._s(_vm.$t("Touch Response")))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "lineBar-slider-group" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "lineBar-slider d-flex",
                                        staticStyle: { width: "100%" }
                                      },
                                      [
                                        _c("v-progress-linear", {
                                          staticStyle: { flex: "3" },
                                          attrs: {
                                            value: _vm.getGraph(
                                              item.touch_sum,
                                              _vm.rateAverage.touchResponse
                                            ),
                                            height: "16"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "lineBar-data ml-3",
                                            staticStyle: { flex: "2" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.touch_sum) +
                                                " " +
                                                _vm._s(_vm.$t("times"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "lineBar-blank-slider d-flex",
                                        staticStyle: { width: "100%" }
                                      },
                                      [
                                        _c("v-progress-linear", {
                                          staticClass: "progress",
                                          staticStyle: { flex: "3" },
                                          attrs: {
                                            value: _vm.getGraph(
                                              _vm.rateAverage.touchResponse,
                                              item.touch_sum
                                            ),
                                            height: "16"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "lineBar-data ml-3",
                                            staticStyle: { flex: "2" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Average")) +
                                                " " +
                                                _vm._s(
                                                  _vm.rateAverage.touchResponse
                                                ) +
                                                " " +
                                                _vm._s(_vm.$t("times"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c("div", [
                    _c("p", { staticClass: "d-flex flex-footer" }, [
                      _vm._v(_vm._s(_vm.$t("No data")))
                    ])
                  ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }