<template>
  <div class="home">
    <h2 class="page-title-bar">
      <i class="ico ico-overview"></i>{{ $t("Overview") }}
    </h2>
    <div class="state-info" style="height: 16px">
      <p></p>
      <ul>
        <li class="state-danger">{{ $t('DANGER') }}</li>
        <li class="state-caution">{{ $t('CAUTION') }}</li>
        <li class="state-stable">{{ $t('STABLE') }}</li>
      </ul>
    </div>

    <div class="main-layout layout01">
      <div class="main-section">
        <h3 class="tit-section">{{ $t('LCD Status') }}</h3>
        <div class="group">
          <v-btn :class="'stateBox lcdStateBox ' + scheduleStatus" @click="clickStatus('scheduled')">
            <dl>
              <dt style="padding-left: 0px;"><strong style="width: 100%; text-align: center;">{{ $t('Scheduled LCD') }}</strong></dt>
              <dd>
                <p>
                  <!-- <strong>{{ Number.isNaN(lcdStatus.scheduled / lcdCount) ? 0 : Math.round((lcdStatus.scheduled / lcdCount) * 100) }}%</strong> -->
                  <strong>{{ getPercent(lcdStatus.scheduled, lcdCount) }}%</strong>
                </p>
                <p>
                  {{ lcdStatus.scheduled ? lcdStatus.scheduled : 0 }}ea / {{ lcdCount }}ea
                </p>
              </dd>
            </dl>
          </v-btn>
          <v-btn :class="'stateBox lcdStateBox ' + noScheduleStatus" @click="clickStatus('noscheduled')">
            <dl>
              <dt style="padding-left: 0px;"><strong style="width: 100%; text-align: center;">{{ $t('No Scheduled LCD') }}</strong></dt>
              <dd>
                <p>
                  <!-- <strong>{{ Number.isNaN(lcdStatus.noScheduled / lcdCount) ? 0 : Math.round((lcdStatus.noScheduled / lcdCount) * 100) }}%</strong> -->
                  <strong>{{ getPercent(lcdStatus.noScheduled, lcdCount) }}%</strong>
                </p>
                <p>
                  {{ lcdStatus.noScheduled ? lcdStatus.noScheduled : 0 }}ea / {{ lcdCount }}ea
                </p>
              </dd>
            </dl>
          </v-btn>
          <v-btn :class="'stateBox lcdStateBox ' + offlineStatus" @click="clickStatus('offline')">
            <dl>
              <dt style="padding-left: 0px;"><strong style="width: 100%; text-align: center;">{{ $t('Offline LCD') }}</strong></dt>
              <dd>
                <p>
                  <!-- <strong>{{ Number.isNaN(lcdStatus.offline / lcdCount) ? 0 : (lcdStatus.offline / lcdCount) * 100 }}%</strong> -->
                  <strong>{{ getPercent(lcdStatus.offline, lcdCount) }}%</strong>
                </p>
                <p>
                  {{ lcdStatus.offline ? lcdStatus.offline : 0 }}ea / {{ lcdCount }}ea
                </p>
              </dd>
            </dl>
          </v-btn>
        </div>
        <div class="stateBox lcdStateBox pa-6">
          <h4 class="tit-sub"><strong>{{ $t('Weekly Consumer Response Rate by Date & Time zone') }}</strong></h4>
          <div class="d-flex flex-row flex-title">
            <div class="d-flex" style="font-size:small; color:#959595;">({{ $t('times') }})</div><div class="d-flex" style="font-size:small; color:#959595;">({{ $t('Average times' )}})</div>
          </div>
          <div class="graph-layout mt-3">
            <div class="graph-section">
              <barChart
                v-show="progressBarShow === 'block'"
                :height="194"
                :updateStatus="updateStatusDate"
              ></barChart>
            </div>
            <div class="graph-section">
              <lineChart
                v-show="progressBarShow === 'block'"
                :height="194"
                :updateStatus="updateStatusTime"
              ></lineChart>
            </div>
          </div>
          <div class="d-flex flex-row flex-footer">
            <div class="d-flex" style="font-size:small; color:#959595; margin-right: 13px;">{{$t('Date')}}</div> <div class="d-flex" style="font-size:small; color:#959595; margin-left: 13px;">{{$t('Time')}}</div>
          </div>

        </div>
      </div>
      <div class="main-section">
        <h3 class="tit-section">{{ $t('Weekly Contents Ranking') }}</h3>
        <perfect-scrollbar :style="{height: '475px'}">
          <v-expansion-panels v-model="expanded" v-if="contentsRank.length !== 0" focusable class="ranking-panel">
            <v-expansion-panel v-for="(item,i) in contentsRank" :key="i">
              <v-expansion-panel-header><span><span> {{ i + 1 }} </span>{{ item.media_name }}</span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="lineBar">
                  <div class="lineBar-title mr-2">{{$t('Play Times')}}</div>
                  <div class="lineBar-slider-group">
                    <div class="lineBar-slider d-flex">
                      <v-progress-linear :value="getGraph(item.play_sum, rateAverage.playTime)" height="16" style="flex:3;"></v-progress-linear>
                      <div class="lineBar-data ml-3" style="flex: 2;">{{ item.play_sum }} {{$t('times')}}</div>
                    </div>
                    <div class="lineBar-blank-slider d-flex" style="width:100%">
                      <v-progress-linear class="progress" :value="getGraph(rateAverage.playTime, item.play_sum)" height="16" style="flex: 3;"></v-progress-linear>
                      <div class="lineBar-data ml-3" style="flex: 2;">{{$t('Average')}} {{ rateAverage.playTime }} {{$t('times')}}</div>
                    </div>
                  </div>
                </div>
                <v-divider class="my-8"></v-divider>
                <div class="lineBar">
                  <div class="lineBar-title mr-2">{{$t('Touch Response')}}</div>
                  <div class="lineBar-slider-group">
                    <div class="lineBar-slider d-flex" style="width: 100%">
                      <v-progress-linear :value="getGraph(item.touch_sum, rateAverage.touchResponse)" height="16" style="flex: 3;"></v-progress-linear>
                      <div class="lineBar-data ml-3" style="flex: 2;">{{ item.touch_sum }} {{$t('times')}}</div>
                    </div>
                    <div class="lineBar-blank-slider d-flex" style="width:100%">
                      <v-progress-linear class="progress" :value="getGraph(rateAverage.touchResponse, item.touch_sum)" height="16" style="flex: 3;"></v-progress-linear>
                      <div class="lineBar-data ml-3" style="flex: 2;">{{$t('Average')}} {{ rateAverage.touchResponse }} {{$t('times')}}</div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-else>
            <p class="d-flex flex-footer">{{$t('No data')}}</p>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import lineChart from '@/lcd/components/lineChart'
// import EventBus from '@/plugins/eventBus'
import barChart from '@/lcd/components/barChart'
import dicamoApi from '@/lcd/plugins/dicamoApi'
import { mapGetters } from 'vuex'
// import commons from '@/plugins/commons'

export default {
  name: 'Overview',
  components: {
    barChart,
    lineChart,
    PerfectScrollbar
  },
  data () {
    return {
      progressBarShow: 'block',
      percentProgress: 0,
      expanded: 0,
      overviewData: [],
      lcdStatus: [],
      updateStatusTime: [],
      updateStatusDate: [],
      contentsRank: [],
      contentsAverage: []
    }
  },
  computed: {
    scheduleStatus () {
      const percent = this.getPercent(this.lcdStatus.scheduled, this.lcdCount)
      if (percent <= 20) {
        return 'danger'
      } else if (percent <= 40) {
        return 'caution'
      }
      return 'stable'
    },
    noScheduleStatus () {
      const percent = this.getPercent(this.lcdStatus.noScheduled, this.lcdCount)
      if (percent <= 20) {
        return 'stable'
      } else if (percent <= 40) {
        return 'caution'
      }
      return 'danger'
    },
    offlineStatus () {
      const percent = this.getPercent(this.lcdStatus.offline, this.lcdCount)
      if (percent <= 20) {
        return 'stable'
      } else if (percent <= 40) {
        return 'caution'
      }
      return 'danger'
    },
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE']
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    lcdCount () {
      if (Number.isNaN(Number(this.lcdStatus.scheduled) + Number(this.lcdStatus.noScheduled))) return 0
      return Number(this.lcdStatus.scheduled) + Number(this.lcdStatus.noScheduled)
    },
    rateAverage () {
      let playTime
      let touchResponse
      this.contentsAverage.forEach(item => {
        if (playTime) console.debug('-')
        else if (item.itemName === 'play') playTime = Math.round(item.icnt)
        else if (item.itemname === 'play') playTime = Math.round(item.icnt)

        if (touchResponse) console.debug('-')
        else if (item.itemName === 'touch') touchResponse = Math.round(item.icnt)
        else if (item.itemname === 'touch') touchResponse = Math.round(item.icnt)
      })
      return {
        playTime: playTime,
        touchResponse: touchResponse
      }
    }
  },
  methods: {
    getGraph (a, b) {
      let percent = 0
      if (Number(a) > Number(b)) {
        percent = 100
      } else {
        percent = Number(a) / Number(b) * 100
      }
      return percent
    },
    getPercent (a, b) {
      if (b < 1) return 0
      let percentage = 0
      percentage = (a / b) * 100
      if (percentage > 0 && percentage < 100) {
        percentage = Math.round(percentage * 10) / 10
        if (percentage % 1 === 0) {
          percentage = percentage.toFixed(1)
        }
      } else {
        percentage = Math.round(percentage)
      }
      return percentage
    },
    clickStatus (status) {
      const _status = String(status)
      this.$router.push({ name: 'DicamoLCDList', params: { type: _status } })
    },
    getOverviewData () {
      const data = new FormData()

      const date = new Date()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('currentDate', date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0))
      data.append('currentTime', date.getHours() + ':' + date.getMinutes())
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getOverviewData.method,
          dicamoApi.requests.getOverviewData.url,
          data
        )
        .then(res => {
          this.overviewData = res.data.data
          this.setResponeData(this.overviewData)

          // this.lcdlist = res.data.data
        })
        .catch(error => {
          this.overviewData = []
          console.debug(`Could not find any overview. error: ${error}`)
        })
    },
    setResponeData (items) {
      this.updateStatusDate = []
      this.updateStatusTime = []

      this.lcdStatus = items.lcdStatus
      items.contentsRank.rank.splice(5, items.contentsRank.rank.length - 1)
      this.contentsRank = items.contentsRank.rank
      this.contentsAverage = items.contentsRank.average
      const updateStatus = items.responseRate
      // for (const key in updateStatus.dateOfThe
      //     updateDate: key,Week) {
      //   this.updateStatusDate.push({
      //     updateCount: updateStatus.date[key]
      //   })
      // }
      updateStatus.dateOfTheWeek.forEach(item => {
        const date = item.date.split('-')
        this.updateStatusDate.push({
          updateDate: date[2],
          updateCount: item.count
        })
      })
      for (const key in updateStatus.time) {
        // if (updateStatus.time[key] !== 0) {
        // this.updateStatusTime.push({
        //   updateDate: key,
        //   updateCount: updateStatus.time[key]
        // })
        this.updateStatusTime[Number(key)] = {
          updateDate: key,
          updateCount: Number(updateStatus.time[key])
        }
        // }
      }
    }
  },
  mounted () {
    // this.setResponeData(this.overviewData)
    this.getOverviewData()
  },
  watch: {
    store: {
      handler () {
        this.getOverviewData()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .graph-layout {
    display: flex;
    justify-content: space-between;
  }
  .graph-layout .graph-section:nth-of-type(1) {
    width: 49%;
  }
  .graph-layout .graph-section:nth-of-type(2) {
    width: 49%;
  }
  .flex-title {
    justify-content: space-between;
  }
  .flex-footer {
    justify-content: center;
  }
  .tit-sub {
    color: #001E38;
    font-weight: bold;
  }
  .progress >>> .v-progress-linear__buffer {
    background: #454f63 !important;
  }
</style>
