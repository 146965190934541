<script>
// 1. Import Chart.js so you can use the global Chart object
import Chart from 'chart.js'
// 2. Import the `generateChart()` method to create the vue component.
import { generateChart } from 'vue-chartjs'

// 3. Extend on of the default charts
// http://www.chartjs.org/docs/latest/developers/charts.html
// Chart.controllers.RoundedBar = Chart.default.Bar
Chart.controllers.RoundedBar = Chart.controllers.bar.extend({
  /* custom magic here */
})

// Chart.defaults.RoundedBar = Chart.defaults.Bar
// Chart.controllers.RoundedBar = Chart.controllers.bar.extend({
// })

// 4. Generate the vue-chartjs component
// First argument is the chart-id, second the chart type.
const RoundedBarChart = generateChart('rounded-bar', 'RoundedBar')

// 5. Extend the CustomLine Component just like you do with the default vue-chartjs charts.
export default {
  extends: RoundedBarChart,
  props: {
    updateStatus: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {},
  watch: {
    updateStatus () {
      this.setMixChart()
    }
  },
  data () {
    return {
      // updateStatus: props.updateStatus
      gradient: []
    }
  },
  methods: {
    getChartlabels () {
      const labels = []
      this.updateStatus.forEach((row) => {
        labels.push(row.updateDate)
      })
      return labels
    },
    getChartData () {
      const data = []
      this.updateStatus.forEach((row) => {
        data.push(row.updateCount)
      })
      return data
    },
    setMixChart () {
      const getLabels = this.getChartlabels()
      const getDatas = this.getChartData()

      this.renderChart(
        {
          labels: getLabels,
          datasets: [
            {
              backgroundColor: this.gradient,
              type: 'bar',
              data: getDatas,
              // backgroundColor: 'rgba(154,199,219,0.5)',
              barThickness: 10
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false, // option 추가
          legend: false,
          tooltips: {
            enabled: true
          },
          scales: {
            x: {
              grid: {
                offset: false
              }
            },
            xAxes: [
              {
                offset: true,
                gridLines: {
                  display: false
                },
                ticks: {
                  fontColor: '#959595', // 폰트컬러
                  fontSize: 11 // 폰트사이즈,
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  fontColor: '#959595',
                  fontSize: 11,
                  suggestedMin: 0,
                  suggestedMax: 80
                  // stepSize: 0.2
                }
              }
            ]
          }
        }
      )
    }
  },
  mounted () {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 360)

    this.gradient.addColorStop(0, '#98CDE6')
    this.gradient.addColorStop(1, '#FFFFFF55')

    if (this.updateStatus !== undefined) {
      this.setMixChart()
    } else {
    }
  }
}
</script>
